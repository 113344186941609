import { featureFlag } from '@atomickit/atomickit-constants';

export const environment = {
	production: true,
  NAME: 'production',
	API_URL: 'https://server.atomictax.com/api/v1',
	REGION: 'us-east-1',
	USER_POOL_ID: 'us-east-1_4i93AjO9v',
	USER_POOL_WEB_CLIENT_ID: 'r7fdcqjh1k0cdi4r6bv4illcv',
	AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
  SENTRY_DSN: 'https://d8ac4d89955f4215be8b7408af90ae1a@o1319042.ingest.sentry.io/6574097',
  UPLOAD_FILES: {
    BUCKET: 'prod-files.atomickit.io',
    REGION: 'us-east-1',
  },
  COGNITO_IDENTITY_POOL_ID: 'us-east-1:6a9e61fa-a0bc-4891-adad-95b79564fc5f',
  STRIPE_PUB_KEY: 'pk_live_1ZM0ULAwYmqSeboV5q8ITy6G7a21WEdRqOUkpKbPQOHi1O4809DdhLGsFzm2PS1gX3pHAhUvNxYhZnBVoyXbDjGLG00dhDLk14r',
  WEBSOCKET_URL: 'wss://server.atomictax.com/ws/v1',
  FEATURE_FLAG: {
    multi_account_switch: featureFlag.FEATURE_FLAG.PROD.multi_account_switch,
    teammates_signup: featureFlag.FEATURE_FLAG.PROD.teammates_signup,
    amazon_linked_account: featureFlag.FEATURE_FLAG.PROD.amazon_linked_account,
    shopify_credit: true,
    suspend_autofiling: featureFlag.FEATURE_FLAG.PROD.suspend_autofiling,
    linked_account_processing: featureFlag.FEATURE_FLAG.PROD.linked_account_processing,
    autofiling_v2: featureFlag.FEATURE_FLAG.PROD.autofiling_v2,

  },
  DRIP_ACCOUNT_ID: '3694945',
  MIX_PANEL_TOKEN: '2a3b1d2d542b5d406b535506e4462eec',
};
