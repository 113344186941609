<table class="table table-hover text-right">
	<thead>
	<tr>
		<th scope="col"></th>
		<th scope="col"></th>
		<th class="fw-bolder" scope="col">{{ heading }}</th>
	</tr>
	</thead>
	<tbody>
	<tr>
		<td>
			Gross Sales
		</td>
		<td>
			<app-amount [amount]="report.reports.gross"></app-amount>
		</td>
		<td></td>
	</tr>
	<tr>
		<td>
			Marketplace Sales
			<ng-template #marketPlaceContent>
				<p>"Marketplace Sales" are included in "Gross Sales" on this report.</p>
				<p>When filing, enter the "Gross Sales" number from this report into line 1 of your return.</p>
				<p>Then, make sure to include the "Marketplace Sales" number from this report in the value you enter on line 2
					of your return.</p>
			</ng-template>
			<ng-template #marketPlaceTitle>
				Marketplace Sales
			</ng-template>
			<span [ngbPopover]="marketPlaceContent" [popoverTitle]="marketPlaceTitle" class="mdi mdi-help-circle text-info"
						triggers="mouseenter:mouseleave">
        </span>
		</td>
		<td>
			<app-amount [amount]="report.reports.market_place_sales"></app-amount>
		</td>
		<td></td>
	</tr>
	<tr>
		<td>
			Deductions/Exemptions
			<ng-template #deductionsExceptionsContent>
				<p>AtomicTax places sales with no sales tax collected in the deductions/exemptions category on your state
					sales tax report.</p>
			</ng-template>
			<ng-template #deductionsExceptionsTitle>
				Deductions/Exemptions
			</ng-template>
			<span [ngbPopover]="deductionsExceptionsContent" [popoverTitle]="deductionsExceptionsTitle"
						class="mdi mdi-help-circle text-info" triggers="mouseenter:mouseleave">
        </span>
		</td>
		<td [ngClass]="{ 'highlight-warning': state === 'expected' }">
			<app-amount
				[amount]="report.reports.deductions_and_exemptions"></app-amount>
		</td>
		<td></td>
	</tr>
	<tr>
		<td>
			Sales Subject to State Tax
			<ng-template #salesSubjectContent>
				<p>AtomicTax sometimes refers to this as Taxed Sales because it is the total sales when tax was collected.</p>
			</ng-template>
			<ng-template #salesSubjectTitle>
				Sales Subject to State Tax
			</ng-template>
			<span [ngbPopover]="salesSubjectContent" [popoverTitle]="salesSubjectTitle"
						class="mdi mdi-help-circle text-info" triggers="mouseenter:mouseleave">
        </span>
		</td>
		<td [ngClass]="{ 'highlight-warning': state === 'expected' }">
			<app-amount
				[amount]="report.reports.sales_subject_to_sales_tax"></app-amount>
		</td>
		<td [ngClass]="{ 'highlight-warning': state === 'expected' }">
			<app-amount
				[amount]="report.reports.total_sales_tax_due"></app-amount>
		</td>
	</tr>

	<ng-container *ngIf="report.reports.breakdown">
		<tr>
			<td>
				<button
					(click)="collapse.toggle()"
					[attr.aria-expanded]="!isBreakdownCollapsed"
					class="btn btn-light p-0"
					type="button"
				>
					State Tax Breakdown
					<span [ngClass]="{ 'mdi-chevron-down': isBreakdownCollapsed, 'mdi-chevron-up': !isBreakdownCollapsed }"
								class="mdi text-info">
						</span>
				</button>
			</td>
			<td></td>
			<td></td>
		</tr>
		<tr #collapse="ngbCollapse" [(ngbCollapse)]="isBreakdownCollapsed">
			<td colspan="4">
				<div class="row">
					<div class="col col-md-7">
						<table class="table small table-info">
							<thead>
							<tr>
								<th>Region</th>
								<th>Gross Sales</th>
								<th>Taxable Sales</th>
								<th>Sales Tax</th>
							</tr>
							</thead>
							<tbody class="table-group-divider border-primary">
							<tr *ngFor="let region_breakdown of report.reports.breakdown.breakdown_detail_compressed">
								<td>{{ region_breakdown.taxRegion }}</td>
								<td>
									<app-amount [amount]="region_breakdown.gross"></app-amount>
								</td>
								<td>
									<app-amount [amount]="region_breakdown.sales_subject_to_sales_tax"></app-amount>
								</td>
								<td>
									<app-amount [amount]="region_breakdown.total_sales_tax"></app-amount>
								</td>
							</tr>

							<tr class="fw-semibold">
								<td>Total Sales Tax</td>
								<td></td>
								<td></td>
								<td>
									<app-amount [amount]="report.reports.total_sales_tax_due"></app-amount>
								</td>
							</tr>

							</tbody>
						</table>
					</div>
					<div class="col col-md-5">
						<table class="table small table-info">
							<thead>
							<tr>
								<th>Category</th>
								<th>Sales Tax</th>
							</tr>
							</thead>
							<tbody class="table-group-divider border-primary">

							<tr>
								<td>State Sales Tax</td>
								<td>
									<app-amount [amount]="report.reports.breakdown.state_tax_total"></app-amount>
								</td>
							</tr>
							<tr>
								<td>City Sales Tax</td>
								<td>
									<app-amount [amount]="report.reports.breakdown.city_tax_total"></app-amount>
								</td>
							</tr>
							<tr>
								<td>County Sales Tax</td>
								<td>
									<app-amount [amount]="report.reports.breakdown.county_tax_total"></app-amount>
								</td>
							</tr>
							<tr>
								<td>Special Sales Tax</td>
								<td>
									<app-amount [amount]="report.reports.breakdown.special_tax_total"></app-amount>
								</td>
							</tr>

							<tr class="fw-semibold">
								<td>Total Sales Tax</td>
								<td>
									<app-amount [amount]="report.reports.total_sales_tax_due"></app-amount>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</td>
		</tr>
	</ng-container>

	<tr class="bg-secondary">
		<td colspan="3">
		</td>
	</tr>
	<tr>
		<td class="text-end" colSpan="2">
			Sales tax payments made
			:
			<ng-template #paymentContent>
				<p>How much sales tax has been paid to the state for the given time period.</p>
			</ng-template>
			<ng-template #paymentTitle>
				<span>Sales Tax Payments</span>
			</ng-template>
			<span [ngbPopover]="paymentContent" [popoverTitle]="paymentTitle" class="mdi mdi-help-circle text-info"
						triggers="mouseenter:mouseleave">
        </span>
		</td>

		<td class="text-end">
			$0.00
		</td>
	</tr>
	<tr [ngClass]="{ 'highlight-warning': state === 'expected', 'highlight-success': state === 'actual' }" class="fw-bolder">
		<td class="text-end" colSpan="2">
			Expected sales tax due on
			<span>{{ report.payment_schedule.filingDate | date:'MMM dd YYYY' }}</span>
			:
			<ng-template #taxDueContent>
				<p *ngIf="state === 'actual'">Minimum amount owed on the next due date to pay sales tax to your state.</p>
				<p *ngIf="state === 'expected'">Expected amount owed on the next due date to pay sales tax to your state.</p>
			</ng-template>
			<ng-template #taxDueTitle>
				<span *ngIf="state === 'actual'">Minimum Sales Tax Due</span>
				<span *ngIf="state === 'expected'">Expected Sales Tax Due</span>
			</ng-template>
			<span [ngbPopover]="taxDueContent" [popoverTitle]="taxDueTitle" class="mdi mdi-help-circle text-info"
						triggers="mouseenter:mouseleave">
        </span>
		</td>
		<td class="text-end">
			<app-amount [amount]="report.reports.total_sales_tax_due"></app-amount>
		</td>
	</tr>
	</tbody>
</table>
