import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  private readonly handleError: HandleError;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('Download Service');
  }

  downloadFile(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<HttpResponse<Blob>>(url, { observe: 'response', responseType: 'blob' as 'json' }).pipe(catchError(this.handleError('Download Service', null)))
  }
}
